import React, { PropsWithChildren } from 'react';

import { Box, Container } from '@mui/material';

import Footer from '../components/Footer';
import Header from '../components/Header';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box sx={{ height: `100%`, display: `flex`, flexDirection: `column` }}>
      <Header />
      <Box pb={3} pt={5} sx={{ flex: 1 }}>
        <Container maxWidth={'lg'}>{children}</Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;

