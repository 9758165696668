import * as React from 'react';

import { Box, Container, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import footerDivider from '../images/footer-divider.svg';
import logo from '../images/logo.png';
import { colors } from '../styles/colors';

const Footer: React.FC = () => {
  return (
    <Box>
      <Box sx={{ backgroundColor: colors.accentColor, p: 6 }}>
        <Container maxWidth={'lg'} sx={{ display: 'flex' }}>
          <Grid2 container rowSpacing={3} columnSpacing={7}>
            <Grid2 xs={12} md={6} alignItems={'center'} alignContent={'center'} justifyItems={'center'} justifyContent={'center'}>
              <Typography variant={'h4'} color={'white'}>
                {
                  'Lainalinja.fi on verkkopalvelu jonka kautta voidaan pyytää lainatarjouksia useilta luotonantajilta. LainaLinja toimii alustana käyttäjälle, lainabrokerina toimii Salus Group Oy.'
                }
              </Typography>
            </Grid2>
            <Grid2 xs={12} md={6} textAlign={'center'}>
              <Typography pb={2} variant={'h3'} color={'white'}>
                {'040-9357620'}
              </Typography>
              <Typography pb={0.5} color={'white'}>
                {'MA-LA 09.00 - 18.00'}
              </Typography>
              <Typography color={'white'}>{'aspa@lainalinja.fi'}</Typography>
            </Grid2>
          </Grid2>
        </Container>
      </Box>
      <Box
        sx={{
          height: '60px',
          backgroundImage: `url(${footerDivider})`,
          backgroundColor: colors.accentColor,
          rotate: '180deg',
          transform: 'scaleX(-1)',
        }}
      ></Box>
      <Box sx={{ backgroundColor: colors.primaryColor, p: 4 }}>
        <Container maxWidth={'lg'} sx={{ textAlign: 'center' }}>
          <Box component={'img'} src={logo} alt={'Lainalinja'} sx={{ width: { xs: '250px', md: '350px' }, pt: 2, pb: 2 }} />
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;

