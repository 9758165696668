import { Link } from 'gatsby';
import * as React from 'react';

import { Box, Container } from '@mui/material';

import logo from '../images/logo.png';

const Header: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: 'white' }} boxShadow={10}>
      <Container maxWidth={'lg'} sx={{ display: 'flex' }}>
        <Link to={'/'} style={{ display: 'flex' }}>
          <Box component={'img'} src={logo} alt={'Lainalinja'} sx={{ width: { xs: '250px', md: '350px' }, pt: 2, pb: 2 }} />
        </Link>
      </Container>
    </Box>
  );
};

export default Header;

